import React from "react"
import PropTypes from "prop-types"
import { Helmet } from "react-helmet"
import { useLocation } from "@reach/router"
import { useStaticQuery, graphql } from "gatsby"

const SEO = ({
  title,
  description,
  lang,
  image,
  article,
  favIconSizes,
  favIconTemplate,
}) => {
  const { pathname } = useLocation()
  const { site } = useStaticQuery(query)

  const {
    defaultTitle,
    titleTemplate,
    defaultDescription,
    siteUrl,
    defaultImage,
    twitterUsername,
  } = site.siteMetadata
  const seo = {
    title: title || defaultTitle,
    description: description || defaultDescription,
    image: `${siteUrl}${image || defaultImage}`,
    url: `${siteUrl}${pathname}`,
  }

  let favIcons = []
  if (favIconSizes && favIconTemplate && favIconSizes.length > 0) {
    favIcons = favIconSizes.map(size => {
      return {
        rel: "icon",
        type: "image/png",
        sizes: `${size}x${size}`,
        href: favIconTemplate.replace(/%s/g, `${size}`),
      }
    })
  }

  return (
    <Helmet
      htmlAttributes={{ lang: lang }}
      title={seo.title}
      titleTemplate={titleTemplate}
      link={favIcons}
      meta={[
        {
          name: "description",
          content: seo.description,
        },
        {
          name: "image",
          content: seo.image,
        },
        {
          name: "og:url",
          content: seo.url,
        },
        {
          name: "og:title",
          content: seo.title,
        },
        {
          name: "og:description",
          content: seo.description,
        },
        {
          name: "og:type",
          content: "website",
        },
        {
          name: "og:image",
          content: seo.image,
        },
        {
          name: "twitter:card",
          content: "summary_large_image",
        },
        {
          name: "twitter:creator",
          content: twitterUsername,
        },
        {
          name: "twitter:tittle",
          content: seo.title,
        },
        {
          name: "twitter:description",
          content: seo.description,
        },
        {
          name: "twitter:image",
          content: seo.image,
        },
      ]}
    />
  )
}

export default SEO

SEO.propTypes = {
  lang: PropTypes.string,
  title: PropTypes.string.isRequired,
  description: PropTypes.string.isRequired,
  image: PropTypes.string,
  favIconSizes: PropTypes.arrayOf(PropTypes.number),
  favIconTemplate: PropTypes.string,
}
SEO.defaultProps = {
  lang: "en",
  title: null,
  description: null,
  image: null,
  article: false,
  favIconSizes: [],
  favIconTemplate: "/images/favicon-%s.png",
}

const query = graphql`
  query SEOQuery {
    site {
      siteMetadata {
        defaultTitle: title
        titleTemplate
        defaultDescription: description
        siteUrl: url
        defaultImage: image
        twitterUsername
      }
    }
  }
`
